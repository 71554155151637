import React from 'react'
import Helmet from 'react-helmet'
import Demo from './demo'
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

const Navbar = loadable(() => import('../components/Navbar'))

const Footer = loadable(() => pMinDelay(import('../components/Footer'), 400))
const TemplateWrapper = ({ children }) => {
    const { title, description } = useSiteMetadata()
    return (
        <div>
            <Helmet>
                <html lang="hu" />
                <title>{`Malib|${title}`}</title>
                <meta name="description" content={description} />
                <meta name="theme-color" content="#fff" />
                <meta name="keywords" content="jóga, yoga, Yoga lessons, Yogastunden, angol, német, spanyol, Nyelvtanfolyamok, Nyelvtanfolyam, Language courses, Sprachkurse, Nyelvórák, Nyelvóra, Language lessons, Sprachstunden, Fertőszentmiklós, Sopron,  " />
                <meta property="og:type" content="business.business" />
                <meta property="og:title" content={title} />
                <meta property="og:url" content="/" />
                <meta property="og:image" content="/img/landing.jpg" />
            </Helmet>

            <Demo />
      <Navbar />
      
      <div>{children}</div>
      <Footer />
        </div>
    )
}
export default TemplateWrapper
